import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import '../../assets/css/buttons/button44.css';
import { getValue, setValue, convertTo2Precision, isObjectEmpty, displayMessage, formatDateTimeString, formatScenarioString } from '../../utils/helper.js';
import { ScenarioOptions } from '../../utils/staticData.js'
import { ApiEnums, getRequest, postRequest } from '../../services/DataRequestService.js';
import * as XLSX from 'xlsx';
import { styleMenu1 } from '../scripts/shared.js';
import { LoaderImage } from '../../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

function ProductionPlanModify() {

    const [furnacesOptions, setFurnacesOptions] = useState([]);
    const [machinesOptions, setMachinesOptions] = useState([]);
    const [scenarioOptions, setScenarioOptions] = useState(ScenarioOptions);

    const [furnaceSelected, setFurnaceSelected] = useState({});
    const [scenarioSelected, setScenarioSelected] = useState({});

    const [loading, setLoading] = useState(false);

    const [skusByMachine, setSkusByMachine] = useState({});

    const [rows, setRows] = useState([{
        id: 1,
        machine: "",
        sku: "",
        replacingSku: "",
        jobChange: "",
        isEditing: true 
    }]);

    const [editingId, setEditingId] = useState(1);

    useEffect(() => {
        const init = async () => {

            const requestObject = {
                "url": ApiEnums.masterdata
            }
            // bind the master data to controls
            const masterData = await getRequest(requestObject);
            setValue('session', 'MasterData', null, masterData);

            const furnacesOptions = masterData
                .map(value => ({ value: value.Furnace, label: value.Furnace }))
                .sort((a, b) => { return a.value.localeCompare(b.value); });
            setFurnacesOptions(arr => arr = furnacesOptions);
            setScenarioOptions(ScenarioOptions)

        }
        init();
    }, [])

    const jobTypes = ["substitute", "swapping"];

    async function getMachinesNSkusData(furnaceVal,scenarioVal) {
        let result = [];
        //     
        try {           
            const requestObject = {
                // url: ApiEnums.productionPlan,
                url: ApiEnums.getMachinesNSkus,
                config: {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        furnace: furnaceVal,
                        scenario: scenarioVal?.[0]
                    }
                },
               // data: scenarioVal
            }
            result = await getRequest(requestObject);



            // extract SKUs and store them in skusByMachine
            let _skuByMachine ={}

            result[0].Data.forEach(item => {
                _skuByMachine[item.Machine] = item.SKUs;
            });
            
            setSkusByMachine(_skuByMachine);

            // extract Machines and store them in machineOptions
            let Machines = Object.keys(_skuByMachine);
            let machineOptions = Machines.map(e => ({ value: e, label: e }))
            setMachinesOptions(machineOptions);

        } catch (err) {
            return 'error';
        }

        return result;
    }


    const handleChangeFurnace = async (selected) => {
        try {

            // set the state varible of furnace
            setFurnaceSelected(selected);
            if (selected !== undefined) {
                //get values in required format to filter
                let valuesOfFurnace = selected?.value;
                if (valuesOfFurnace && !(Object.keys(scenarioSelected).length === 0 && scenarioSelected.constructor === Object)) {
                    //await filterMachinesOnFurnaceAndScenarios(valuesOfFurnace, scenariosValue);
                    await getMachinesNSkusData(valuesOfFurnace,scenarioSelected.value);
                } else {
                    setMachinesOptions([]);
                }
            }
        } catch (err) {
            throw err
        }
    };

    const handleChangeScenario = async (selected) => {  
        console.log(selected)     
        setScenarioSelected(selected);
        if (!(Object.keys(selected).length === 0 && selected.constructor === Object) 
                && !(Object.keys(furnaceSelected).length === 0 && furnaceSelected.constructor === Object)) {
            let furnaceValue = furnaceSelected.value;
            let scenarioValue = selected.value;
            //await filterMachinesOnFurnaceAndScenarios(furnaceValue, scenariosValue);
            await getMachinesNSkusData(furnaceValue,scenarioValue);
        } else {
            setMachinesOptions([]);
        }
    };

    const handleAddRow = () => {
        const newRow = {
            id: rows.length + 1,
            machine: "",
            sku: "",
            replacingSku: "",
            jobChange: "",
            isEditing: true
        };
        setRows([...rows, newRow]);
    };

    const handleDeleteRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const handleEditRow = (id) => {
        setRows(rows.map(row => 
            row.id === id 
                ? { ...row, isEditing: true } 
                : { ...row, isEditing: false }
        ));
        setEditingId(id);
    };

    const handleSaveEdit = (id) => {
        const row = rows.find(row => row.id === id);
        if(row['manchine'] === "" || row['sku'] === "" || row['replacingSku'] === "" || row['jobChange'] === ""){
            displayMessage('warning', 'Invalid Input', 'Please fill all the fields');
            return;
        }else{
            setRows(rows.map(row => 
                row.id === id 
                    ? { ...row, isEditing: false } 
                    : row
            ));
            setEditingId(null);
        }
    };

    const updateRow = (id, field, value) => {

        setRows(rows.map(row => {
            if (row.id === id) {
                const updatedRow = { ...row, [field]: value };
                // Clear SKU when machine changes
                if (field === 'machine') {
                    updatedRow.sku = '';
                }
                return updatedRow;
            }
            return row;
        }));
    };

    const handleSubmit = () => {
        const formattedData = rows.map(row => [
            row.machine,
            row.sku,
            row.replacingSku,
            row.jobChange
        ]);
        console.log('Submitted data:', formattedData);
        // Here you can handle the submission to your backend
    };

    return (
        <Wrapper>
            <div className="box">
                <center><h1>Modify Production plan</h1></center>
                <div className="filter-section">
                    <div className="drp3">
                        <div className='control'>
                            <label>Furnace</label>

                            <Select
                                key="Furnace"
                                options={furnacesOptions}
                                onChange={handleChangeFurnace}
                                value={furnaceSelected}
                                className="custom-select"
                                styles={styleMenu1}
                            />
                        </div>
                        <div className='control'>
                            <label>Scenario</label>
                            <Select
                                key="Scenario1"
                                options={scenarioOptions}
                                onChange={handleChangeScenario}
                                value={scenarioSelected}
                                hasSelectAll={false}
                                className="custom-select"
                            />
                        </div>
                    </div>
                </div>
                <TableContainer>
                    <TableWrapper>
                        <StyledTable>
                            <thead>
                                <tr>
                                    <th>Machines</th>
                                    <th>List of SKUs</th>
                                    <th>Replacing SKU</th>
                                    <th>Job Change</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row) => (
                                    <Tr key={row.id}>
                                        <Td>
                                            {row.isEditing ? <SelectControl
                                                value={row.machine}
                                                onChange={(e) => updateRow(row.id, 'machine', e.target.value)}
                                            >
                                                <option value="">Select Machine</option>
                                                {machinesOptions.map((machine) => (
                                                    <option key={machine.value} value={machine.value}>
                                                        {machine.value}
                                                    </option>
                                                ))}
                                            </SelectControl> : <label>{row.machine}</label>}
                                        </Td>
                                        <Td>
                                            {row.isEditing ? <SelectControl
                                                value={row.sku}
                                                onChange={(e) => updateRow(row.id, 'sku', e.target.value)}
                                                disabled={!row.machine && !loading}
                                            >
                                                <option value="">Select SKU</option>
                                                {row.machine && skusByMachine[row.machine]?.map((sku) => (
                                                    <option key={sku} value={sku}>
                                                        {sku}
                                                    </option>
                                                ))}
                                            </SelectControl> : <label>{row.sku}</label>}
                                        </Td>
                                        <Td>
                                            {row.isEditing ? <Input
                                                type="text"
                                                value={row.replacingSku}
                                                onChange={(e) => updateRow(row.id, 'replacingSku', e.target.value)}
                                                placeholder="Enter new SKU"
                                            /> : <label>{row.replacingSku}</label>}
                                        </Td>
                                        <Td>
                                            {row.isEditing ? <SelectControl
                                                value={row.jobChange}
                                                onChange={(e) => updateRow(row.id, 'jobChange', e.target.value)}
                                            >
                                                <option value="">Select Job Type</option>
                                                {jobTypes.map((job) => (
                                                    <option key={job} value={job}>
                                                        {job}
                                                    </option>
                                                ))}
                                            </SelectControl> : <label>{row.jobChange}</label>}
                                        </Td>
                                        <Td>
                                            <ButtonGroup>

                                                {editingId ? <IconButton onClick={() => handleSaveEdit(row.id)}>
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </IconButton> : <IconButton onClick={() => handleEditRow(row.id)}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </IconButton>}
                                                <IconButton onClick={() => handleDeleteRow(row.id)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </IconButton>
                                            </ButtonGroup>
                                        </Td>
                                    </Tr>
                                ))}
                            </tbody>
                        </StyledTable>
                    </TableWrapper>

                    <ButtonContainer>
                        <AddButton onClick={handleAddRow}>
                            <FontAwesomeIcon icon={faPlus} />
                            Add Row
                        </AddButton>

                        <button className='button-24 cust-btn' onClick={handleSubmit}>
                            Submit
                        </button>
                    </ButtonContainer>
                </TableContainer>
            </div>

        </Wrapper>
    )
}

export default ProductionPlanModify

const TableContainer = styled.div`
  margin: 0px 15px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  background-color: #f8f9fa;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 12px;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #f5f5f5;
  }
`;

const SelectControl = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;

  &:disabled {
    background-color: #f5f5f5;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const IconButton = styled.button`
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;

  &:hover {
    color: #333;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const SubmitButton = styled.button`
  padding: 8px 16px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0052a3;
  }
`;

const Wrapper = styled.div`
padding-top:20px;

.box{
  display:flex;
  flex-direction:column;  
  height:fit-content;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07);
  min-height: 85vh;
}

.custom-select{
  width:400px;
}


.filter-section{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin-bottom:1rem;
  width:100%;

  div.filter-button-controls{
    margin:1rem;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    gap:2rem;
    .control{
      width:33%;
    }
  }

.drp3{
  display:grid;
  margin:1rem;
  row-gap:1rem;
  grid-template-columns:auto auto;
  width:75%;
}
  div.filter-item:last-child{
    align-items:center;
  }
  label{
    font-size:20px;
  }
}

.download-section{
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  margin-top:20px;
  margin-right:200px;
}

.cust-btn{
  width:12rem;
}

.table-container{
  width:99%;
  height:30vh;
  overflow:scroll;
  margin: 0 0.5rem;
  margin-bottom : 2rem;

}
.error-container , .table-section{
  margin: 0 0.5rem;
}

.table-section > div > p{
    padding-left : 0.5rem;
    font-size: 20px;
    padding-bottom: 1rem;
    background: var(--clr-white);
    color: #04AA6D;
    line-height: 1.5;
    font-weight:bold;
    text-transform: capitalize;
}

.err-text{
  font-size : 16px;
  color : red;
  margin-left : 10px;
}
`