import React from 'react'
import styled from 'styled-components'


function Footer() {
  return (
    <Wrapper>
     <div>
     <p className='footer-text'>Powered by: Quation Solutions Private Ltd, Bangalore, India</p>
     </div>
    </Wrapper>
  )
}


export default Footer;

const Wrapper = styled.footer`
background-color: var(--quation-primary-2);
flex-grow:1;
color:var(--clr-white);

.footer-text{
  text-align:center;
  font-size:16px;
  margin:0.5rem 0;

}
`