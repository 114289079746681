import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select'
import '../assets/css/buttons/button44.css';
import TableComponent from '../components/common/BasicTable.js';
import { getValue, setValue, displayMessage, isObjectEmpty, formatScenarioString , convertTo2Precision , formatDateTimeString} from '../utils/helper.js';
import { ScenarioOptions } from '../utils/staticData.js'
import { styleMenu1 } from './scripts/shared.js'
import { ApiEnums, getRequest, postRequest  } from '../services/DataRequestService.js';
import { columnsFurnaceDraw, columnsKPIMetrics } from '../components/common/TableColumns';
import * as XLSX from 'xlsx';
import { LoaderImage } from '../assets';




function FurnaceDraw() {

  const gridContainerRef = useRef(null);

  const [tabularData, setTabularData] = useState([]);

  const [furnacesOptions, setFurnacesOptions] = useState([]);
  const [scenarioOptions, setScenarioOptions] = useState(ScenarioOptions);

  const [furnaceSelected, setFurnaceSelected] = useState([]);
  const [scenarioSelected, setScenarioSelected] = useState([]);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [loading, setLoading] = useState(false);



  // Function to update grid template areas based on number of items
  function updateGridAreas() {
    const gridItems = gridContainerRef.current.querySelectorAll('.grid-item');

    // Check if grid container reference is available
    if (gridContainerRef.current) {
      // Check the number of grid items
      const numItems = gridItems.length;

      if (numItems === 4) {
        gridContainerRef.current.style.gridTemplateAreas = `
            "kpi kpi"
            "tbl tbl"
          `;
      } else if (numItems === 2) {
        gridContainerRef.current.style.gridTemplateAreas = `
            "kpi"
            "tbl"
          `;
      } else  if (numItems === 6) {
        // Default grid-template-areas for other cases
        gridContainerRef.current.style.gridTemplateAreas = `
            "kpi kpi kpi"
            "tbl tbl tbl"
          `;
      }else{
         // Default grid-template-areas for other cases
         gridContainerRef.current.style.gridTemplateAreas = `
         "kpi"
         "tbl"
       `;
      }
    }
  }

  function formatData(entityData) {
    entityData?.forEach(function (entry) {
      entry["Planned Draw(MT/day)"] = parseFloat(entry["Planned Draw(MT/day)"]).toFixed(0);
      entry["Actual Draw(MT/day)"] = parseFloat(entry["Actual Draw(MT/day)"]).toFixed(0);
    });
    return entityData;
  }


  useEffect(() => {
    const init = async () => {

      const requestObject = {
        "url": ApiEnums.masterdata
      }
      // bind the master data to controls
      const masterData = await getRequest(requestObject);
      const furnacesOptions = masterData
        .map(value => ({ value: value.Furnace, label: value.Furnace }))
        .sort((a, b) => { return a.value.localeCompare(b.value); });
      setFurnacesOptions(arr => arr = furnacesOptions);
    }

    if (tabularData?.length > 0) {
      updateGridAreas();
    }

    init();
  }, [tabularData]);





  const handleChangeFurnace = async (selected) => {
    try {
      setShowDownloadButton(false);
      setFurnaceSelected(selected);
      setTabularData([]);
    } catch (err) {
      throw err
    }
  };

  const handleChangeScenario = (selected) => {
    setShowDownloadButton(false);
    setTabularData([]);
    let selectedCount = selected?.length;
    const selectedValues = selected.map(item => item.value);
    let scenarioOpt = ScenarioOptions;
    switch (selectedCount) {
      case 3:
        scenarioOpt.forEach(option => {
          if (!selectedValues.includes(option.value)) {
            option.disabled = true;
          }
        });
        setScenarioOptions(scenarioOpt);
        break;
      case 2:
      case 1:
      case 0:
        scenarioOpt.forEach(option => {
          option.disabled = false;
        });
        setScenarioOptions(scenarioOpt);
        break;
      default:
        console.log(`logical error in handleChangeScenario selectedCOunt :${selectedCount} `)
        break;
    }
    setScenarioSelected(selected);
  };

  const downloadOnClickHandler = () => {
    try {
      if (furnaceSelected?.value) {
        const downlaodableData = getValue('session', 'FurnaceDraw');
        if (downlaodableData?.length > 0) {
          exportToExcelMultiSheet(downlaodableData, `FurnaceDraw-${furnaceSelected?.value}`);
        }
        else {
          console.log('No data found for download')
        }
      }
      else {
        displayMessage('error', 'Select Furnace', 'Please select any Furnace');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onClickHandlerGenerateReport = async () => {
    try {


      if (isObjectEmpty(furnaceSelected)) {
        displayMessage('warning', 'Invalid Input', 'Please Select Furnace');
        return false;
      }

      if (scenarioSelected?.length === 0) {
        displayMessage('warning', 'Invalid Input', 'Please Select Scenario');
        return false;
      }

      setLoading(true);
      let result = await getFurnaceDrawData();
      setLoading(false);
      if (result?.length === 0) {
        displayMessage('warning', 'No Data Found', 'No Data Found');
        return;
      }

      if (result !== 'error') {
        setShowDownloadButton(true);
        setTabularData(result);

        setValue('session', 'FurnaceDraw', null, result);
      } else {
        setTabularData([]);
        setValue('session', 'FurnaceDraw', null, []);
        displayMessage('warning', 'No Data Found', 'No Data Found');
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getFurnaceDrawData() {
    let result = [];
    try {
      let arrScenarios = scenarioSelected?.map(item => item.value);
      const reqDatafurnaceDraw = {
        url: ApiEnums.furnaceDraw,
        config: {
          headers: {
            "Content-Type": "application/json"
          },
          params: {
            furnace: furnaceSelected.value
          }
        },
        data: arrScenarios
      }
      result = await postRequest(reqDatafurnaceDraw);
    } catch (err) {
      return 'error';
    }
    return result;
  }

  function exportToExcelMultiSheet(tabData, fileName) {
    const workbook = XLSX.utils.book_new();
    for (let dataItem of tabData) {
      if(dataItem?.data !== null){
        let worksheet = XLSX.utils.json_to_sheet(dataItem.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, dataItem.scenario);
       
      }
      if(dataItem['kpi-metrics'] !== null){
        let worksheet2 = XLSX.utils.json_to_sheet(dataItem['kpi-metrics']);
        XLSX.utils.book_append_sheet(workbook, worksheet2, `${dataItem.scenario}-kpi`);
      }     
    }
    // Get the sheet names
    const sheetNames = workbook.SheetNames;

    if (sheetNames.length > 0) {
          XLSX.writeFile(workbook, fileName + '.xlsx');
    } else {
      displayMessage('warning', 'No Content', 'No Content to download');
    }
  }



  function renderTableSection(tabularData) {   
    if (tabularData?.length === 0) {
      return false;
    } 
    return <>
      <div ref={gridContainerRef} className="table-section"> {tabularData.map((tdata, index) => (
        (tdata['scenario'] !== null && tdata['kpi-metrics'] !== null) ?
        <div className='grid-item found table-kpi' key={index}>
          <p>KPI Metrics of {formatScenarioString(tdata.scenario)}</p>
          <div className="table-container-kpi">
            <TableComponent inputData={formatData(tdata['kpi-metrics'])} columnNames={columnsKPIMetrics} />
          </div>
        </div> : 
          <div className='grid-item notfound'>
        <p  className='err-text'>Kpi Metrics report not found for scenario {formatScenarioString(tdata.scenario)}</p>
        </div>
        ))}
      
        {tabularData.map((tdata, index) => ( ( tdata['scenario'] !== null && tdata.data !== null ) ?
          <div className='grid-item found table-tbl' key={index}> <p>{formatScenarioString(tdata.scenario)}</p>
            <div className="table-container">
              <TableComponent inputData={formatData(tdata?.data)} columnNames={columnsFurnaceDraw} />
            </div>
          </div> : 
           <div className='grid-item notfound'>
           <p className='err-text' >Furnace Draw report not found for scenario {formatScenarioString(tdata.scenario)}</p>
           </div>
        ))}
      </div>
    </>
  }

  return (
    <Wrapper>

      <div className="box">
        <center><h1>Furnace Draw</h1></center>
        <div className="filter-section">
          <div className="filter-item">
            <div className='control'>
              <label>Furnace</label>

              <Select
                key="Furnace"
                options={furnacesOptions}
                onChange={handleChangeFurnace}
                value={furnaceSelected}
                className="custom-select"
                styles={styleMenu1}
              />
            </div>
            <div className="control">
              <label>Scenario</label>
              <MultiSelect
                key="Scenario1"
                options={scenarioOptions}
                onChange={handleChangeScenario}
                value={scenarioSelected}
                hasSelectAll={false}
                className="custom-select"
              />
            </div>
          </div>
          <div className="filter-button-controls">
            <div className='control'>
              <button id="btnGenerateReport" className='button-24 cust-btn' onClick={onClickHandlerGenerateReport} >Generate Report</button>
            </div>
            {showDownloadButton && <div className='control'>
              <button className='button-24 cust-btn' onClick={downloadOnClickHandler} >Download</button>
            </div>}
          </div>
        </div>
        { loading && <div className='center-align loader'>
              <img src={LoaderImage} width="100px" height="100px" />
              <br />
              <span> Fetching Furnace Draw... Please wait... </span>
            </div>}
        {tabularData && renderTableSection(tabularData)}
      </div>
    </Wrapper>
  )
}

export default FurnaceDraw;

const Wrapper = styled.div`
padding-top:20px;

.box{
  display:flex;
  flex-direction:column;  
  height:fit-content;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07);
  min-height: 85vh;

}

.custom-select{
  width:400px;
}

.filter-section{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin-bottom:1rem;

  div.filter-button-controls{
    margin:1rem;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    gap:2rem;
    .control{
      width:33%;
    }
  }
  div.filter-item{
    margin:1rem;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    gap:2rem
  }
  div.filter-item:last-child{
    align-items:center;
  }
  label{
    font-size:20px;
  }
}

.download-section{
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  margin-top:20px;
  margin-right:200px;
}

.cust-btn{
  width:12rem;
}

.table-container{
 
  height:50vh;
  overflow:scroll;
  margin: 0 0.5rem;
  margin-bottom : 3rem;
}
.table-container-kpi{
  width:fit-content;
  margin: 0 0.5rem;
  margin-bottom : 3rem;
}
.error-container , .table-section {
  margin: 0 0.5rem;
}
.table-section{
  display:grid;
  grid-template-areas:
    "kpi  kpi"
    "tbl  tbl";
  width:98%;
}



.table-section > div.found  p{
    padding-left : 0.5rem;
    font-size: 20px;
    padding-bottom: 1rem;
    background: var(--clr-white);
    color: #04AA6D;
    line-height: 1.5;
    font-weight:bold;
    text-transform: capitalize;
    
}

.table-kpi{
  grid-area:kpi kpi;
}
.table-tbl{
  grid-area:tbl tbl;
}

.grid-item {
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
   // border:2px dotted red;
    overflow: auto;
}

.err-text{
  font-size : 16px;
  color : red;
  margin-left : 10px;
}

`