import React,{useEffect} from 'react'
import { Outlet , Link , useLocation } from 'react-router-dom';
import styled from 'styled-components';
import '../../assets/css/buttons/button71.css';
import $ from 'jquery'

 function AdminAction(props) {
  const { state } = useLocation();
  useEffect(()=>{
	var selector = '.nav_items a';

	$(selector).on('click', function(){
		$(selector).removeClass('button-71-active');
		$(this).addClass('button-71-active');
	});
},[])
  return (
    <Wrapper>
      <input type="checkbox" name="MenuToggle" id="MenuToggle"></input>
      <aside className="sidebar">
      <nav>     
      <div className="nav_items">
         {/* <Link to="AdminAction/OptimiserResult">Motive 1</Link>
         <a href="/OptimiserResult">Optimizer Result</a>
         <a href="#">Production Plan</a>
         <a href="#">Furnace Draw</a> */}
		 <Link to="/" className='button-71'>Admin Controls</Link>
		 {/* <Link to="UserAccountControl" className={`button-71 sm-font  ${(state === 'UserAccountControl')?'button-71-active' : ''} `}>User Account Control</Link> */}
		 <a href={process.env.REACT_APP_KEYCLOAK_ADMIN_URL} target='_blank' className={`button-71 sm-font  ${(state === 'UserAccountControl')?'button-71-active' : ''} `}>User Account Control</a>		 

         <Link to="ReportGeneration" className={`button-71  ${(state === 'ReportGeneration')?'button-71-active' : ''} `}>Report Generation</Link>
         <Link to="AdminToolsSelection" className={`button-71  ${(state === 'ResultView')?'button-71-active' : ''} `}>Result View</Link>
      </div>
   </nav>
      </aside>
      <main className="right">
   <label for="MenuToggle" className="toggle__icon">
   <span className="line line1"></span>
   <span className="line line3"></span>
   <span className="line line2"></span>
   </label>
   <div className="content">
      <Outlet></Outlet>
   </div>
</main>
    </Wrapper>
  )
}

export default AdminAction;

const Wrapper = styled.div`
	display: flex;
#MenuToggle {
	display: none;
	/* position: absolute;
 top: 30px;
 left: 350px; */
}

a {
	text-decoration: none;
	color: #fff;
}

.nav_items {
	width: 100%;	
	margin-left:0.5rem;
}

.nav_items a {
	display: block;

	text-align: center;
	transition: all .4s ease;
	white-space:nowrap;
}

.nav_items a:hover,
.nav_items a.active {
	background: var(--clr-primary-3);
}

.sidebar {
	position: relative;
	width: 250px;
	height: 100%;
	opacity: 1;
	transform: translateX(0);
	transition: all .8s ease;
}


.right {
	position: relative;
	width: calc(100% - 250px);
	height: 100%;
	padding: 30px 30px;
	overflow-x: hidden;
	display: flex;
	align-items: center;
	transition: all .8s ease;
}

.content {
	width: 100%;
}

.content h2 {
	font-size: 60px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .3px;
	color: #000831;
	text-align: center;
}


/* Toggle bar */

.toggle__icon {
	position: absolute;
	top: 6px;
	left: 30px;
	cursor: pointer;
}

.toggle__icon .line {
	width: 24px;
	height: 3px;
	background-color: #000831;
	margin: 6px 0;
	display: block;
	border-radius: 8px;
}


/* If Checked */

#MenuToggle:checked~.sidebar {
	width: 0;
	transform: translateX(-250px);
}

#MenuToggle:checked~.right {
	width: 100%;
}

.sm-font{
	white-space:nowrap;
}
`